import React, { useEffect, useState } from 'react';
import './Companies.css';
import CreateCompany from './CreateCompany';
import ViewCompany from './ViewCompany/ViewCompany';
import { useNavigate } from 'react-router-dom';
import FilterAPIs from '../../../MasonyFixingsAPIs/FilterAPIs/FilterAPIs';
import {Form, Row, Col} from 'react-bootstrap';


export default function Companies(){

    const [addNew, setAddNew] = useState(false);
    const navigate = useNavigate();
       
    const [searchString, setSearchString] = useState('');
    const [CompaniesData, setCompaniesData] =  useState([])

    const getAllCompanies = (string) => {

        const postJSON = {
            name: string
        }
        FilterAPIs.PostCompanyFilter(postJSON)
        .then((response) => {
            setCompaniesData(response.data);
        }) 
        .catch((err) => {
            console.log(err)
            if(err.response.status === 401){
                if(err.response.data.logout){
                    navigate('/login');
                }
                else{
                    window.alert(err.response.data.message)
                }
            }
        })
    }

    const handleAddNew = () => {
        getAllCompanies();
        setAddNew(false);
    }

    const handleCancel = () => {
        setAddNew(false);
    }

    useEffect(() => {
        getAllCompanies(searchString);
    }, [searchString])
    
    return(
        <div className='company-display'>

            <h1>Companies</h1>
            <hr/>

            {addNew ?
                <div>
                    <CreateCompany handleCancel={handleCancel} handleAddNew={handleAddNew} /> 
                </div>
            :
            
            <div>
                <button onClick={() => setAddNew(true)}>Add New</button>

                <Row className="mb-2">
                    <Col sm={6}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Search Company: </Form.Label>
                            <Col>
                                <Form.Control 
                                    value={searchString} 
                                    onChange={(e) => setSearchString(e.target.value)} 
                                />
                            </Col>
                        </Form.Group>   
                    </Col>
                    <Col>
                        <h5>Showing: {CompaniesData.length}</h5>
                    </Col>
                </Row>

                {CompaniesData.map((company) => {
                    return(
                        <ViewCompany key={company.id} getCompanies={getAllCompanies} companyID={company.id}/>
                    )
                })}
            </div>
            }
        </div>
    )
}
