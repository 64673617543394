import React from "react";
import CategoryMaintenance from "./CategoryMaintenance/CategoryMaintenance";

export default function CategorySectorMaintenance(){

    return(
        <div>
            <CategoryMaintenance/>
        </div>
    )
}