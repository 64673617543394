import React, { useEffect, useState } from "react";
import ProjectsTable from "../ProjectsTable/ProjectsTable";
import FilterAPIs from "../../../../MasonyFixingsAPIs/FilterAPIs/FilterAPIs";
import ApiResponseHandler from "../../../../MasonyFixingsAPIs/ApiResponseHandler";
import {useNavigate} from 'react-router-dom';
import {Form, Row, Col} from 'react-bootstrap';

function ProjectsHome(){

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState('')
    const [projectList, setProjectList] = useState([]);
    
    const getProjectList = (string) => {
       
        const postJSON = {
            name: string
        }
        FilterAPIs.PostProjectFilter(postJSON)
        .then((projects) => {
            setProjectList(projects.data);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err)
            ApiResponseHandler(err.response, navigate);
        })
    }

    useEffect(() => {
        getProjectList(searchString);
    }, [searchString]);
    if(loading){
        return(
            <h4>Loading Project List...</h4>
        )
    }
    else{
        return(
            <div> 
                <Row className="mb-2">
                    <Col sm={6}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Search Project: </Form.Label>
                            <Col>
                                <Form.Control 
                                    value={searchString} 
                                    onChange={(e) => setSearchString(e.target.value)} 
                                />
                            </Col>
                        </Form.Group>   
                    </Col>
                </Row>
                
                           
                <ProjectsTable projectList={projectList} getProjectList={getProjectList} />
            </div>
        );
    }
    

    
}
export default ProjectsHome;