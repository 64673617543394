import React from "react";

export default function Analytics(){

    return(
        <div>
            <div>Analytics page</div>
            <div>will show graphs and informaiton about projects</div>
        </div>
    )
}