import { api } from "../ApiConfig";

const FilterAPIs = {

    PostCompanyFilter: (postJSON) => {
        const response = api.request({
            url: 'filter/company',
            data: postJSON,
            method: 'POST'
        })
        return response;
    },
    
    PostProjectFilter: (postJSON) => {
        const response = api.request({
            url: 'filter/project',
            data: postJSON,
            method: 'POST'
        })
        return response;
    }
}

export default FilterAPIs;